<template>
  <div class="box">
    <!-- <div class="baidu-api" id="allmap1"></div> -->
    <div class="ignore">
      <div class="baidu-api" id="allmap1"></div>
      <div id="mouseOffset" class="mouseOffset" v-if="editRegion != 1" :style="{
      position: 'absolute',
      'z-index': '9999999',
      top: offsetTop + 'px',
      left: offsetLeft + 'px',
      display: isShowMouse,
    }">
      双击{{ editRegion == 3 ? "创建" : "保存" }}
    </div>
    </div>
    <div class="search flex_align" v-if="isCustom">
      <div class="search_input flex_align">
        <el-autocomplete id="suggestId" style="width: 100%" @focus="focus" @blur="blur" v-model="search"
          :fetch-suggestions="querySearchAsync" :trigger-on-focus="false" placeholder="搜地点" @select="handleSelect">
          <template slot-scope="{ item }">
            <div class="flex_align search_title" style="width: 100%">
              <i class="el-icon-search fl mgr10" style="margin-right: 10px"></i>
              <div class="flex_align">
                {{ item.title }} ({{ item.address }})
                <!-- <div class="title">{{ item.title }} ({{ item.address }})</div> -->
                <!-- <span class="address ellipsis">({{ item.address }})</span> -->
              </div>
            </div>
          </template>
        </el-autocomplete>
        <div class="search_x mouse" @click="eliminate">×</div>
      </div>
      <div class="search_but mouse" @click="query()">
        <img src="@/assets/group/search.png" alt="" />
      </div>
    </div>
    <mapcustomfence ref="mapcustomfence" :markList="markList" :mapIconDisplay="mapIconDisplay"
      @openDrawing="openDrawing" @drawPolylines="drawPolylines" @drawCircle="drawCircle" @drawRectangle="drawRectangle"
      @previousStep="previousStep" @deleteFenceArea="deleteFenceArea" :baiduMap="map"
      @switchOnlineState="switchOnlineState" @changeMap="changeMap">
    </mapcustomfence>
    <msgTip :showcontent="msgTipData.showcontent" :tipstext="msgTipData.tipstext"></msgTip>
    <enclosure ref="enclosure" v-if="isBool" :dialogTitle="dialogTitle" @clearOverwrites="clearOverwrites" :efId="efId"
      :overlays="overlays"></enclosure>
    <upmarker ref="upmarker" :markerUser="markerUser" v-if="dialogModifyMarker" @successMarker="successMarker"
      @cloneMarker="cloneMarker"></upmarker>
    <mapmark v-if="dialogModifyMark" @successMark="successMark" @cloneMark="cloneMark" :mark="mark" ref="mapmark">
    </mapmark>
    <!-- <div id="mouseOffset" class="mouseOffset" v-if="editRegion != 1" :style="{
      position: 'absolute',
      'z-index': '9999999',
      top: offsetTop + 'px',
      left: offsetLeft + 'px',
      display: isShowMouse,
    }">
      双击{{ editRegion == 3 ? "创建" : "保存" }}
    </div> -->
  </div>
</template>

<script src=""></script>
<script>
import axios from "axios";
import qs from "qs";
import mapcustomfence from "@/components/mapcustomfence.vue";
import msgTip from "@/components/history/deletepop.vue";
import upmarker from "@/components/map/upmarker.vue";
import mapmark from "@/components/map/mapmark.vue";
import { getQueryUserIcon, getQueryMark } from "@/administration/upmarker.js";
import beasconfig from "@/api/beasconfig.js";
import enclosure from "@/components/enclosure/establishEnclosure.vue";
import EventBus from "@/eventBus/eventBus.js";
import { _throttle } from "@/method/utils.js";
import global from "@/method/globalMethod.js";
import GeoUtils from "bmaplib.geoutils";
import close_label from "../../assets/map/close_label.png";
import sOpen from "../../assets/map/open.png"; //关闭图标
import xOpen from "../../assets/map/open1.png"; //关闭图标
const enclosureUrlapi = beasconfig.enclosureUrlapi;
const gpsUrlapi = beasconfig.gpsUrlapi;
export default {
  name: "enclosureMap",
  components: { mapcustomfence, msgTip, enclosure, upmarker, mapmark },
  props: {
    // mapData: {
    //   //群组改变数据
    //   type: Array,
    //   default() {
    //     return [];
    //   }
    // }
  },
  data() {
    return {
      mapIconDisplay: false,
      map: "",
      type: 1,
      isShow: true,
      msgTipData: {
        showcontent: false, //是否显示
        tipstext: "", //文字提示
      },
      overlays: [],
      coordinate: [], //全部对讲用户
      distinguishType: 0,
      traffic: "", //路况
      theFirstTime: 0, //初次
      frequency: 0, //次数
      temporaryTime: null, //计时器
      oneself: {
        lat: "",
        lng: "",
      },
      search: "",
      recordType: 0, //记录类型
      editRegion: 1, //编辑区域
      groupData: [],
      pointPosition: [],
      searchLocation: [],
      pointList: "", //经纬度数据
      isBool: false, //显示编辑弹出框
      efId: 0,
      dialogTitle: "",
      // temporaryTimeOne:null,   //30秒没有操作就调用地图刷新
      offsetLeft: 0,
      offsetTop: 0,
      isShowMouse: "none",
      showArea: "",
      isCustom: true,
      userInfo: {}, //用户信息
      BmapType: BMAP_NORMAL_MAP,
      dialogModifyMarker: false,
      dialogModifyMark: false,
      markerUser: {},
      markerUserOverlapId:0,
      mark: {}, //标记点
      recordMark: [],
      recordMarker: "",
      markList: [], //标记点
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  // beforeMount: function() {

  // },
  mounted() {
    let that = this;
    that.baiduMap();
    setTimeout(() => {
      //延迟0.5s防止兄弟传值失效
      that.init();
      if (that.$store.state.groupData.length > 0) {
        //判断是否有选中名称
        that.efId = that.$store.state.groupData[0].efId
          ? that.$store.state.groupData[0].efId
          : 0;
        that.switchOnlineState(that.type);
      }
    }, 500);
    window.closeLabel = this.closeLabel;
    window.clickShowUserInfo = this.clickShowUserInfo;
    window.expand = this.expand;
    // 监听全局鼠标点击事件
    window.addEventListener("click", this.clickShowUserInfo);
  },
  beforeDestroy() {
    this.clearTimer();
    // this.frequency = 0;
    this.theFirstTime = 0;
    this.$store.state.efRemark = "1";
    // this.cancellationMap();
    EventBus.$off("changeMapTow");
    EventBus.$off("mapHoldall");
    EventBus.$off("position");
    EventBus.$off("editEnclosureMap");
    EventBus.$off("emptyMap");
    EventBus.$off("mouseout");
    this.distanceTool.close();
    // 移除监听器，避免内存泄漏
    window.removeEventListener('click', this.clickShowUserInfo);
  },
  methods: {
    // 方法集合
    init() {
      let that = this;
      EventBus.$on("position", function () {
        //当前位置
        // console.log(that.oneself)
        // if(that.oneself.lat =='' || that.oneself.lng ==''){
        //   that.oneselfPosition(1);
        // }else{
        //   that.map.setViewport([that.oneself]);
        // }
        if (that.pointPosition.length > 0) {
          that.map.setViewport(that.pointPosition);
        } else {
          that.map.centerAndZoom("北京", 15);
        }
        that.search = "";
        // that.eliminateCheck();
      });
      EventBus.$on("mapHoldall", function (value) {
        //工具箱
        if (value == 0) {
          //测距
          that.distanceTool.open();
        } else {
          that.drawingManager.open(); //开启绘制
          that.drawingManager.setDrawingMode(BMAP_DRAWING_MARKER);
        }
      });
      EventBus.$on("changeMapTow", function (row, type) {
        // that.clearTimer();
        that.pointPosition = [];
        that.overlays = [];
        that.recordType = type;
        that.efId = row.efId;
        that.map.ondblclick = null;
        that.map.clearOverlays(); //清楚覆盖物
        that.theFirstTime = 0;
        that.editRegion = 1;
        that.onmouseout();
        if (that.efId > 0 && row.children && row.children.length <= 0) {
          that.getFenceDetails(1);
        }
        that.getDrawingManager();
      });
      EventBus.$on("editEnclosureMap", function (row) {
        //编辑围栏
        that.map.clearOverlays(); //清楚覆盖物
        that.theFirstTime = 0;
        that.clearOverwrites();
        that.efId = row.efId;
        that.dialogTitle = "编辑围栏";
        that.overlays = [];
        if (row) {
          that.editRegion = 2;
          that.onmouseout(1);
          that.getFenceDetails(2);
          that.getDrawingManager();
        }
      });
      EventBus.$on("emptyMap", function (type) {
        //清除地图覆盖物
        if (type == 1) {
          that.editRegion = 1;
        } else {
          that.editRegion = 3;
        }
        that.theFirstTime = 0;
        that.onmouseout(1);
        that.initialization();
        // that.pointPosition = [];
        // that.efId = 0;
        // that.map.clearOverlays(); //清楚覆盖物
        // that.dialogTitle = "创建围栏";
        // that.map.ondblclick = null;
        // that.$store.state.efRemark = '1';
        // that.$store.state.groupData = [];
        // that.distinguishType = 0;
        // that.getDrawingManager();
      });
      EventBus.$on("mouseout", function () {
        //清除地图覆盖物
        that.onmouseout();
      });
    },
    initialization() {
      //初始化
      let that = this;
      that.pointPosition = [];
      that.efId = 0;
      that.map.ondblclick = null;
      that.$store.state.efRemark = "1";
      that.$store.state.groupData = [];
      that.dialogTitle = "创建围栏";
      that.distinguishType = 0;
      that.map.clearOverlays(); //清楚覆盖物
      that.getDrawingManager();
    },
    onmouseout(type) {
      let that = this;
      if (that.distinguishType > 0) {
        //离开百度地图并且在绘制多边形的时候刷新地图
        if (that.distinguishType == 1) {
          that.cancellationMap();
          that.baiduMap();
        }
        that.drawingManager.close(); //停止绘制
        that.overlays = [];
        that.isShowMouse = "none";
        that.distinguishType = 0;
        that.$refs.mapcustomfence.selectState.isCustom = false;
        that.isCustom = true;
        if (type != 1) {
          //不等于的话需要刷新
          setTimeout(() => {
            that.switchOnlineState(that.type);
          }, 100);
        }
      } else {
        if (type != 1) {
          that.switchOnlineState(that.type);
        }
      }
    },
    cancellationMap() {
      //注销地图
      let that = this;
      try {
        // 注销地图（换成你初始化时定义的地图变量）
        that.map = null;

        // 获取interval的最高ID然后遍历清除
        const HIGHEST_INTERVAL_ID = setInterval(";");
        for (let i = 0; i < HIGHEST_INTERVAL_ID; i++) {
          clearInterval(i);
        }

        // 获取百度地图创建的DOM并销毁
        const BAIDU_MAPS = document.querySelectorAll(".baidu-api");
        BAIDU_MAPS.forEach((item) => {
          document.body.removeChild(item);
        });
      } catch (e) {
        // console.log(e);
      }
    },
    /*围栏详情*/
    getFenceDetails() {
      axios
        .get(
          `${enclosureUrlapi}-${this.$store.state.userState.server}/electronicFence/${this.efId}`
        )
        // axios.get(`/liang/electronicFence/${this.efId}`)
        .then((res) => {
          // var that = this;
          // console.log("获取围栏详情", res);
          var effectiveDates = [];
          // var validPeriods = [];
          var data = res.data.data;
          this.fenceName = data.efName; //围栏名称
          this.showArea = data.efRemark; //是否显示
          this.triggerMethod = data.efType; //禁出 禁入
          this.ddyId = data.ddyId; //调度员id
          this.pointList = data.pointList; //获取经纬度坐标
          this.pointListss = data.pointList; //获取经纬度坐标
          let [startTime, endTime] = data.effectiveTimes.split("|"); //时段
          this.$store.state.efRemark = data.efRemark;
          if (data.validStartTime != 0) {
            this.validStartTime = this.$moment(data.validStartTime).format(
              "YYYY-MM-DD"
            ); //有效日期
            this.validEndTime = this.$moment(data.validEndTime).format(
              "YYYY-MM-DD"
            ); //有效日期结束
          } else {
            this.validStartTime = "";
            this.validEndTime = "";
          }
          const dateTest = this.$moment(new Date()).format("YYYY-MM-DD");
          // console.log("有时候无值", startTime, endTime);
          if (
            startTime == undefined ||
            startTime == "" ||
            endTime == undefined ||
            endTime == ""
          ) {
            this.validPeriod = "";
          } else {
            startTime = dateTest + " " + startTime;
            endTime = dateTest + " " + endTime;
            this.validPeriod = [new Date(startTime), new Date(endTime)];
          }

          this.dayOfTheWeek = data.periodData.split(","); //星期几
          this.dayOfTheWeek = this.dayOfTheWeek.map(function (data) {
            return +data;
          });
          effectiveDates[0] = this.validStartTime;
          effectiveDates[1] = this.validEndTime;
          this.effectiveDate = effectiveDates;
          /*画围栏*/
          this.drawFence();
          // console.log("日期", this.effectiveDate);
          // console.log("时段", validPeriods);
        });
    },
    /*区域编辑*/
    locationData() {
      this.hintDivShow = true;
      if (this.showArea == "1") {
        this.polygon.enableEditing(); //开启编辑
      }
      this.createInformationWindow = false; //创建窗口
      this.mapOndblclick();
    },
    establish() {
      //创建
      let that = this;
      that.isBool = false;
      that.isShowMouse = "none";
      // that.baiduMap();    //解决双击地图自动放大
      that.$nextTick(() => {
        that.isBool = true;
        setTimeout(() => {
          that.$refs.enclosure.openPg();
        }, 300);
      });
    },
    // 根据点的数组自动调整缩放级别
    setZoom(bPoints, type) {
      if (type == 1) {
        return;
      }
      // console.info("bPoints：》" + bPoints);
      var view = this.map.getViewport(eval(bPoints));
      var mapZoom = view.zoom;
      var centerPoint = view.center;
      this.map.centerAndZoom(centerPoint, mapZoom);
      this.theFirstTime = 1;
    },
    eliminate() {
      //清除
      this.search = "";
      this.searchLocation = [];
    },
    querySearchAsync(queryString, cb) {
      if (queryString != "" && queryString != null) {
        var options = {
          onSearchComplete: function (res) {
            //检索完成后的回调函数
            // console.log(res)
            var s = [];
            if (local.getStatus() == BMAP_STATUS_SUCCESS) {
              for (var i = 0; i < res.getCurrentNumPois(); i++) {
                s.push(res.getPoi(i));
              }
              cb(s);
            } else {
              cb(s);
            }
          },
        };
        var local = new BMap.LocalSearch(this.map, options); //创建LocalSearch构造函数
        local.search(queryString); //调用search方法，根据检索词str发起检索
      }
    },
    query() {
      if (this.search != "" && this.search != null) {
        if (this.editRegion == 1) {
          this.map.setViewport(this.searchLocation);
        } else {
          this.map.clearOverlays();
          this.judgeType();
          this.$store.state.efRemark = "2";
          this.getAdministrativeArea({ title: this.search });
          this.mapOndblclick();
        }
      } else {
        this.searchLocation = [];
      }
      // this.eliminateCheck();
      EventBus.$emit("emptyChoice");
    },
    handleSelect(item) {
      this.search = item.title;
      this.searchLocation = [item.point]; //搜索选中的定位信息
      // let name = item.title;
      // this.editRegion = 3;
      // this.eliminateCheck();
      if (this.editRegion == 1) {
        this.map.setViewport([item.point]);
      } else {
        this.map.clearOverlays();
        this.judgeType();
        this.$store.state.efRemark = "2";
        this.getAdministrativeArea(item);
        this.mapOndblclick();
        EventBus.$emit("emptyChoice");
      }
    },
    mapOndblclick() {
      this.map.ondblclick = () => {
        this.$store.state.toUpdateEnclosure = 0;
        this.search = "";
        if (this.editRegion == 2) {
          // console.log('用户已双击')
          this.getEnclosure();
        } else {
          this.establish();
        }
        this.map.ondblclick = null;
      };
    },
    judgeType() {
      //判断是编辑还是创建
      if (this.efId > 0 && this.editRegion == 2) {
        this.dialogTitle = "编辑围栏";
        this.editRegion = 2;
      } else if (this.editRegion == 3) {
        this.dialogTitle = "创建围栏";
        this.editRegion = 3;
      }
    },
    /*画围栏*/
    async drawFence() {
      var that = this;
      var list = [];
      let color;
      let fillColor;
      if (that.editRegion == 1) {
        color = "#00FEFF";
        fillColor = "rgba(8,34,47,0.73)";
      } else {
        color = "#ff3c00";
        fillColor = "rgba(255,60,0,0.3)";
      }
      if (that.showArea == "2") {
        var pointLists = that.pointList.split(";");
        var pointLists1 = pointLists.join("|").split("|");
        await pointLists1.map((item) => {
          var pt = item.split(",");
          var pts = new BMap.Point(pt[0], pt[1]);
          list.push(pts);
        });

        for (var i = 0; i < pointLists.length; i++) {
          var boundaries = pointLists[i].replace(/\|/g, ";");
          var polygon = new BMap.Polygon(boundaries, {
            strokeStyle: "dashed",
            strokeWeight: 2,
            strokeColor: color,
            fillColor: fillColor,
            strokeOpacity: 1,
          });
          polygon.id = 5;
          that.map.addOverlay(polygon); //添加覆盖物
        }
      } else {
        var pointLists = that.pointList.split("|");
        await pointLists.map((item) => {
          var pt = item.split(",");
          var pts = new BMap.Point(pt[0], pt[1]);
          list.push(pts);
        });
        var polygon = new BMap.Polygon(list, {
          strokeStyle: "dashed",
          strokeWeight: 2,
          strokeColor: color,
          fillColor: fillColor,
          strokeOpacity: 1,
        });
        polygon.id = 5;
        that.map.addOverlay(polygon);
        polygon.addEventListener("lineupdate", (e) => {
          that.overlays = e.target.getPath();
        });
        that.polygon = polygon;
        that.overlays = polygon.getPath();
      }
      if (that.editRegion == 2) {
        that.locationData();
      }
      // this.map.setViewport(polygon.getPath());  //调整视野
      if (that.editRegion == 1) {
        let array = that.pointPosition;
        array.forEach((item1) => {
          if (item1) {
            var pts = new BMap.Point(item1.lng, item1.lat);
            list.push(pts);
          }
        });
      }
      setTimeout(function () {
        /*整体自适应居中*/
        that.setZoom(list, that.theFirstTime);
      }, 300);
    },
    getAdministrativeArea(row) {
      var that = this;
      this.map.clearOverlays();
      var bdary = new BMap.Boundary();
      bdary.get(row.title, function (rs) {
        //获取行政区域
        var count = rs.boundaries.length; //行政区域的点有多少个
        if (count > 0) {
          let list = [];
          let list1 = [];
          let pointLists;
          // that.overlays = { list: that.pointList };
          for (var i = 0; i < count; i++) {
            list.push(rs.boundaries[i].replace(/;/g, "|"));
            var ply = new BMap.Polygon(rs.boundaries[i], {
              strokeStyle: "dashed",
              strokeWeight: 2,
              strokeColor: "#ff3c00",
              fillColor: "rgba(255,60,0,0.3)",
              strokeOpacity: 1,
            }); //建立多边形覆盖物
            ply.id = 5;
            that.map.addOverlay(ply); //添加覆盖物
            // that.map.setViewport(ply.getPath()); //调整视野
          }
          pointLists = rs.boundaries.join(";");
          pointLists = pointLists.split(";");
          pointLists.map((item) => {
            var pt = item.split(",");
            var pts = new BMap.Point(pt[0], pt[1]);
            list1.push(pts);
          });
          that.pointList = list.join(";");
          that.overlays = list;
          that.setZoom(list1);
        } else {
          that.map.setViewport(that.searchLocation);
        }
      });
    },
    getEnclosure() {
      //更新围栏
      let that = this;
      let array = this.overlays;
      let pointList = "";
      if (array && that.$store.state.efRemark == 1) {
        // this.coordinateCalculationArea(array);
        that.pointList = "";
        for (var i = 0; i < array.length; i++) {
          var lat = array[i].lat;
          var lng = array[i].lng;
          if (pointList == null || pointList == "") {
            pointList = lng + "," + lat;
          } else {
            pointList = pointList + "|" + lng + "," + lat;
          }
        }
        /*经纬度*/
        that.pointList = pointList;
      }
      let parmas = {
        companyId: parseInt(that.userInfo.User_CompanyID),
        groupId: 0,
        efRemark: that.$store.state.efRemark, //省市区
        pointType: 0, //地图类型
        ddyId: parseInt(that.userInfo.User_ID), // 调度员id
        pointList: that.pointList, //经纬度数据
      };
      if (that.efId > 0) {
        parmas.efId = parseInt(that.efId); //围栏id
      }
      axios
        .post(
          `${enclosureUrlapi}-${this.$store.state.userState.server}/electronicFence/save`,
          parmas
        )
        .then((res) => {
          if (res.data.code == 200) {
            // if(that.$store.state.efRemark == 1){    //多边形会开启双击放大
            //   that.baiduMap();    //解决双击地图自动放大
            // }
            that.clearOverwrites();
            that.$store.state.toUpdateEnclosure = 2;
            that.editRegion = 1;
            that.efId = 0;
            // that.overlays = [];
            that.getDrawingManager();
            // that.map.clearOverlays();
            that.isShowMouse = "none";
            that.map.ondblclick = null;
            that.$MessageSuccess("更新成功");
          } else {
            that.$MessageWarning(res.data.message);
          }
        });
    },
    startTime() {
      //30s刷新地图
      //开始计时
      let that = this;
      that.clearTimer();
      if (that.editRegion != 1) {
        return;
      }
      function timerTow() {
        return setTimeout(() => {
          // that.qcMarker();
          if (that.editRegion == 1) {
            // that.switchOnlineState(that.type);
            that.preventFrequentlyRefresh();
          }
        }, 30000);
      }
      that.temporaryTime = timerTow();
    },
    clearTimer() {
      if (this.temporaryTime != null) {
        clearInterval(this.temporaryTime); // 清除定时器
        this.temporaryTime = null;
      }
    },
    changeMap(type, show) {
      //改变地图状态
      if (type == 1) {
        //显示
        this.isShow = show;
        if (this.editRegion == 1) {
          this.map.clearOverlays(); //清楚覆盖物
        } else {
          this.qcMarker();
        }
        if (show) {
          this.$store.state.fenceLable = []; //如何点击从false为true就把记录删除Lable的值给回到最初。
        }
        let groupData = this.$store.state.groupData;
        if (
          this.efId > 0 &&
          groupData.length > 0 &&
          groupData[0].children &&
          groupData[0].children.length <= 0
        ) {
          this.getFenceDetails(1);
        } else {
          this.switchOnlineState(this.type);
        }
      } else if (type == 2) {
        //切换地图类型
        show
          ? this.map.setMapType(BMAP_NORMAL_MAP)
          : this.map.setMapType(BMAP_HYBRID_MAP);
        this.BmapType = show ? BMAP_NORMAL_MAP : BMAP_HYBRID_MAP;
        let setMaxZoom = show ? 20 : 19;
        this.map.setMaxZoom(setMaxZoom); //设置地图缩放的最大值
      } else if (type == 3) {
        if (show) {
          this.traffic = new BMap.TrafficLayer(); //  *  创建交通流量图层实例`
          this.map.addTileLayer(this.traffic);
        } else {
          this.map.removeTileLayer(this.traffic);
        }
      } else if (type == 4) {
        if (show) {
          //放大
          this.map.zoomIn();
          // this.map.zoomTo(this.map.getZoom() + 1);
        } else {
          //缩小
          this.map.zoomOut();
          // this.map.zoomTo(this.map.getZoom() - 1);
        }
      } else if (type == 5) {
        //测距
        this.distanceTool.open();
        //myDis.close();  //关闭鼠标测距
      } else if (type == 6) {
        //标注
        this.drawingManager.open(); //开启绘制
        this.drawingManager.setDrawingMode(BMAP_DRAWING_MARKER);
      }
    },
    switchOnlineState(type, name) {
      let groupData = this.$store.state.groupData;
      if (name) {
        this.$store.state.fenceLable = [];
      }
      this.clearTimer();
      this.qcMarker();
      this.clearOverwrites();
      this.getMarkList();
      this.type = type;
      // this.eliminateCheck(1);
      //切换在线状态
      this.coordinate = []; //每次更新数据都清空
      if (groupData.length <= 0) {
        return;
      }
      let arr = [];
      let userArray;
      let uid;
      let array = groupData;
      if (array.length > 0 && array[0].children) {
        array.forEach((item) => {
          arr.push(...item.children);
        });
      } else {
        arr = array;
      }
      if (type == 0 || !array[0].children) {
        //全部
        userArray = this.deweight(arr);
        uid = userArray.map((item) => item.id);
      } else if (type == 1) {
        //在线人数
        userArray = this.deweight(arr).filter((item) => item.online == 3);
        uid = userArray.map((item) => item.id);
      } else {
        //不在线人
        userArray = this.deweight(arr).filter((item) => item.online != 3);
        uid = userArray.map((item) => item.id);
      }
      this.pointPosition = [];
      this.markers = [];
      this.coordinate = this.deweight(arr).filter((item) => item.online == 3);
      if (userArray.length > 0) {
        this.getAnchorPoint(uid, userArray);
      } else if (
        this.efId > 0 &&
        groupData[0].children &&
        groupData[0].children.length > 0
      ) {
        this.getFenceDetails(1);
      }
      this.startTime();
    },
    getMarkList(){
      this.recordMark.forEach(item =>{
        this.map.removeOverlay(item);
      })
      this.recordMark = [];
      getQueryMark({
        orgId: this.$store.state.userInfo.org_id
      }).then(res =>{
        // console.log(res)
        let markList = res.data.data;
        if(markList.length > 0){
          markList.forEach(item =>{
            this.setMarkImg(item)
          })
          this.markList = markList;
        }else{
          this.markList = [];
        }
      })
    },
       /*用户id获取定位点*/
       getAnchorPoint(uid, users) {
      // console.log('用户', uid);
      var that = this;
      let uids = uid.join(",");
      var params = {
        Uids: uids, //再线成员用户id
        CorrdinateType: "baidu"
      };
      getQueryUserIcon({
        orgId:this.$store.state.userInfo.org_id,
        userIds:uids
      }).then(row =>{
        let imgs = row.data.data;
        axios
        .post(
          gpsUrlapi + "/shanli/gps/api/locations/LastLocation?",
          qs.stringify(params),
          {
            headers: beasconfig.tokenSl
          }
        )
        .then(res => {
          // console.log("定位返回", res);
          if (res.data.Data.length > 0) {
            var locationData = res.data.Data;
            for (var i = 0; i < users.length; i++) {
              for (var j = 0; j < locationData.length; j++) {
                if (users[i].id == locationData[j].Uid) {
                  // console.log("id----1111", users[i].id);
                  users[i].pot = locationData[j];
                }
              }
            }
                    //  users.push({
                    //     id:36990,
                    //     label:'亚古兽啊阿萨达萨达萨达',
                    //     online:1,
                    //     pot:{
                    //       Lat:22.579106,
                    //       Lng:113.946213,
                    //       Rtime:'2023-06-02 14:16:31'
                    //     }
                    //   })
                    // users.push({
                    //     id:36992,
                    //     label:'亚古兽啊阿萨达',
                    //     online:3,
                    //     pot:{
                    //       Lat:22.579106,
                    //       Lng:113.946213,
                    //       Rtime:'2023-11-10 9:40:31'
                    //     }
                    //   })
                    //   users.push({
                    //     id:36991,
                    //     label:'亚古兽啊阿萨达萨达达',
                    //     online:1,
                    //     pot:{
                    //       Lat:22.579106,
                    //       Lng:113.946213,
                    //       Rtime:'2023-06-02 14:16:31'
                    //     }
                    //   })
                    //   users.push({
                    //     id:36990,
                    //     label:'亚古兽啊阿萨达萨asd',
                    //     online:1,
                    //     pot:{
                    //       Lat:22.579106,
                    //       Lng:113.946213,
                    //       Rtime:'2023-06-02 14:16:31'
                    //     }
                    //   })
                    //   users.push({
                    //     id:36990,
                    //     label:'亚古兽啊阿萨达萨达萨达',
                    //     online:1,
                    //     pot:{
                    //       Lat:22.579106,
                    //       Lng:113.946213,
                    //       Rtime:'2023-06-02 14:16:31'
                    //     }
                    //   })
                    //   users.push({
                    //     id:36990,
                    //     label:'亚古兽啊阿萨达萨达萨达',
                    //     online:1,
                    //     pot:{
                    //       Lat:22.579106,
                    //       Lng:113.946213,
                    //       Rtime:'2023-06-02 14:16:31'
                    //     }
                    //   })
            var gps ={}; //找出相同的定位点
            users.forEach(function(item) {
              let n = imgs.find(m => m.user_id == item.id);
              if (item.pot) {
                var pint =`${item.pot.Lng}${item.pot.Lat}`;
                if(n){
                  // item.img = `${beasconfig.xinextendUrlapi}/icon${n.img}`;
                  item.img = n.img;
                } 
                if(gps[pint]){    //判断是否有这个数组
                  gps[pint].push(item);
                }else{
                  gps[pint]= [item];
                }
                if (that.type == 1) {
                  that.coordinate.push(item);
                }
                var pt = new BMap.Point(item.pot.Lng, item.pot.Lat);
                // var mar = new BMap.Marker(pt,{ icon: null});    //icon等于空隐藏点聚合icon
                // that.markers.push(mar);
                that.pointPosition.push(pt);
                item.isExceed = item.online==3?that.InspectionTime(item.pot.Rtime):false;
              }
            });

            for (const key in gps) {
              that.setMarker(gps[key]);
            }
            if(that.$store.state.efId > 0 &&  that.$store.state.groupData.length > 0){
              that.getFenceDetails(1);
            }else{
              that.setZoom(that.pointPosition,that.theFirstTime);
            }
          } else {
            that.getFenceDetails(1);
            if (that.recordType == 1) {
              that.nullMessage();
            }
          }
        });
      })
    },
    nullMessage: _throttle(function () {
      //没数据的时候提示
      this.recordType = 0;
      this.$MessageWarning("暂无数据");
    }, 500),
    preventFrequentlyRefresh: _throttle(function () {
      //阻止频繁刷新
      this.getMarkList();
      this.switchOnlineState(this.type);
    }, 1000),
    eliminateCheck(type) {
      //清除绘制选中
      if (this.distinguishType != 0) {
        if (type != 1) {
          this.clearOverwrites();
        }
        this.$refs.mapcustomfence.selectState.isCustom = false;
        this.isCustom = true;
        this.drawingManager.close();
      }
    },
    deweight(arr) {
      //数组去重
      let de_arr = [];
      arr.forEach((i) => {
        let isTrue = de_arr.every((j) => j.id != i.id);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    oneselfPosition(type) {
      //当前位置
      let that = this;
      const geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        // console.log(r);
        that.oneself = {
          lat: r.point.lat,
          lng: r.point.lng,
        };
        if (type == 1) {
          that.map.setViewport([
            {
              lat: r.point.lat,
              lng: r.point.lng,
            },
          ]);
        }
      });
    },
    /*百度地图*/
    baiduMap() {
      var that = this;
      var map = new BMap.Map("allmap1", {
        enableMapClick: false,
        restrictCenter: false,
      });
      map.centerAndZoom("北京", 10);
      map.enableScrollWheelZoom(true);
      map.disableInertialDragging(); //禁用地图惯性拖拽
      map.disableDoubleClickZoom(); // 禁用双击放大
      map.setDefaultCursor("inherit"); //修改鼠标样式
      map.setMapType(that.BmapType);
      // 测距功能
      that.distanceTool = new BMapLib.DistanceTool(map, { lineStroke: 2 });
      // //监听测距尺关闭的时候触发的事件
      // // that.distanceTool.addEventListener("removepolyline", function (e) {
      // //   console.log("removepolyline");
      // //   // console.log(e);
      // // });
      // // //监听测距尺鼠标点击添加点的时候触发的事件
      // // that.distanceTool.addEventListener("addpoint", function (e) {
      // //   console.log("addpoint");
      // // });
      // // //监听测距尺鼠标右击测距尺添加完成的时候触发的事件
      // // that.distanceTool.addEventListener("drawend", function (e) {
      // //   that.distanceTool.close();
      // // });
      if (that.traffic && that.traffic != "") {
        that.traffic = new BMap.TrafficLayer(); //  *  创建交通流量图层实例`
        map.addTileLayer(that.traffic);
      }
      map.addEventListener("mousemove", function (e) {
        if (e.cb && e.cb.id == 5 && that.editRegion != 1) {
          that.isShowMouse = "block";
          that.offsetLeft = e.pixel.x - 15;
          that.offsetTop = e.pixel.y - 30;
        } else {
          that.isShowMouse = "none";
        }
      });
      let loading = true;
      map.addEventListener('tilesloaded', () => {
        if(loading){
          this.map.setMapStyleV2({styleId: 'cacc9d791d69a8ba64e0c43e4bb43858'}); // 地图图块加载完成后设置地图风格
          loading = false;
        }
      });
      that.map = map;
      that.getDrawingManager();
      that.getMarkList();
    },
    getDrawingManager() {
      //重置绘制工具
      let that = this;
      let color;
      let fillColor;
      if (that.editRegion == 1) {
        color = "#00FEFF";
        fillColor = "rgba(8,34,47,0.73)";
      } else {
        color = "#ff3c00";
        fillColor = "rgba(255,60,0,0.3)";
      }
      //实例化鼠标绘制工具
      var styleOptions = {
        strokeColor: color, //边线颜色。
        fillColor: fillColor, //填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 2, //边线的宽度，以像素为单位。
        strokeOpacity: 0.8, //边线透明度，取值范围0 - 1。
        fillOpacity: 0.6, //填充的透明度，取值范围0 - 1。
        strokeStyle: "dashed", //边线的样式，solid或dashed。
      };
      // cursor:"inherit",
      var drawingManager = new BMapLib.DrawingManager(that.map, {
        isOpen: false, //是否开启绘制模式
        enableDrawingTool: false, //是否显示工具栏
        drawingToolOptions: {
          anchor: BMAP_ANCHOR_TOP_RIGHT, //位置
          offset: new BMap.Size(5, 5), //偏离值
        },
        circleOptions: styleOptions, //圆的样式
        polylineOptions: styleOptions, //线的样式
        polygonOptions: styleOptions, //多边形的样式
        rectangleOptions: styleOptions, //矩形的样式
      });
      drawingManager.addEventListener("overlaycomplete", function (e) {
        console.log("绘制完成返回", e);
        var allOverlay = that.map.getOverlays();
        console.log('allOverlay', allOverlay)
        if (e.overlay.ja && e.overlay.ja.length < 4) {
          that.mapIconDisplay = true;
          that.clearOverwrites();
          if (that.distinguishType == 1) {
            that.hint("自定义区域点数少于3个,请重新划!");
            setTimeout(() => {
              that.drawPolylines();
            }, 200);
          } else if (that.distinguishType == 2) {
            that.drawCircle();
          } else if (that.distinguishType == 3) {
            that.drawRectangle();
          }
        } else {
          if (e.drawingMode == "marker") {
            that.mark = e.overlay.point;
            that.setMarkImg(that.mark);
            that.dialogModifyMark = true;
            that.drawingManager.close(); //停止绘制
            that.map.removeOverlay(e.overlay);
            return;
          }
          that.overlays = e.overlay.getPath();
          that.msgTipData.tipstext = "确定保存围栏吗？";
          that.hintDivShow = false;
          that.drawingManager.close(); //停止绘制
          that.clearOverwrites(); //删除绘制图形
          if (that.editRegion == 1) {
            that.shapeType(e.overlay);
            that.startTime();
          } else {
            that.polygonMap(); //添加绘制的图形
            that.judgeType();
            that.$store.state.efRemark = "1";
            EventBus.$emit("emptyChoice");
          }
          that.$refs.mapcustomfence.selectState.isCustom = false;
          that.isCustom = true;
          that.distinguishType = 0;
          // drawingManager.disableCalculate(); //停止面积计算
          // that.efId = 0;
          // that.dialogTitle = "创建围栏";
          // that.startTime();
        }
      });
      that.drawingManager = drawingManager;
    },
    polygonMap() {
      //绘制地图
      let that = this;
      var list = [];
      // var pointLists = this.pointList.split("|");
      that.overlays.map((item) => {
        var pts = new BMap.Point(item.lng, item.lat);
        list.push(pts);
      });
      var polygon = new BMap.Polygon(list, {
        strokeStyle: "dashed",
        strokeWeight: 2,
        strokeColor: "#ff3c00",
        fillColor: "rgba(255,60,0,0.3)",
        strokeOpacity: 1,
      });
      that.map.addOverlay(polygon);
      polygon.addEventListener("lineupdate", (e) => {
        // console.log("e", e);
        that.overlays = e.target.getPath();
      });
      polygon.addEventListener("mousemove", function (e) {
        that.isShowMouse = "block";
        that.offsetLeft = e.pixel.x - 15;
        that.offsetTop = e.pixel.y - 30;
      });
      polygon.addEventListener("mouseout", function (e) {
        that.isShowMouse = "none";
      });
      that.polygon = polygon;
      that.polygon.enableEditing();
      that.mapOndblclick();
    },
    closeLabel(id) {
      //移除label
      var t = document.getElementById(`baidu${id}`); //选取label为百度baidu的id进行隐藏
      // t.style.display = 'none';// 以块级样式隐藏
      t.remove();
      this.markers.forEach((item) => {
        if (item.id == id) {
          item.getLabel().content = "";
        }
      });
      this.$store.state.fenceLable.push(id);
    },
    InspectionTime(time) {
      //验证时间
      let Rtime = this.$moment(time).valueOf(); //定位上报时间
      let Dtime = this.$moment(new Date()).valueOf(); //当前时间
      let Ztime = 60 * 1000 * 5; //5分钟
      let Mtime = Dtime - Rtime; //当前时间减去定位上报时间
      let isExceed = Mtime >= Ztime; //判断是否超过五分钟
      return isExceed;
    },
    expand(id,type){  //展开用户信息
      var z = document.getElementById(`expand2${id}`);//展开
      var elements = document.querySelectorAll(`#expand1${id}`);
      elements.forEach(function(element) {  
        element.style.display = type == 2?'block':'none';// 以块级样式隐藏
      });
      z.style.display = type == 2?'none':'block';// 以块级样式隐藏
    },
    clickShowUserInfo(id,zid,event){  //展示用户信息
      // zid 是父盒子的id
      if(id && zid){  //判断是点击的用户
        this.markerUserOverlapId = zid;
        let m = this.markers.find(item => item.row.id == zid);
        let row = m.arr.find(item => item.id == id);
        var t = document.getElementById(`userMarker${zid}`);//选取label为百度baidu的id进行隐藏
        t.style.display = 'block';// 以块级样式隐藏
        t.innerHTML = `<div>
                         <div>
                            <div>名称：${row.label}</div>
                            <div class='baidu_lAbel_space' >日期：${row.pot.Rtime}</div>
                            <div>定位方式：${row.pot.positionType==2?'基站定位':'北斗/GPS'}</div>
                            <div>方向：${global.stringDirection(row.pot.Direction)}</div>
                            <div>海拔：${row.pot.altitude}</div>
                            <div class='baidu_lAbel_space' >速度(KM/H)：${row.pot.Speed}</div>
                            <div style="width:100%;height:1px;background-color: #fff;margin:10px auto;" ></div>
                            <div style="white-space: break-spaces;font-size: 12px;">温馨提示：数据来自第三方，会存在误差，仅供参考。 </div>
                         </div>
                    </div>`
        event.preventDefault();
        // 阻止事件冒泡
        event.stopPropagation();
      }else{
        if(this.markerUserOverlapId > 0){
          var t = document.getElementById(`userMarker${this.markerUserOverlapId}`);//选取label为百度baidu的id进行隐藏
          if(t){
            t.style.display = 'none';// 以块级样式隐藏
            this.markerUserOverlapId = 0;
          }
        }
      }
    },
    successMark(){  //成功修改自定义照片
      this.getMarkList();
      this.dialogModifyMark = false;
    },
    cloneMark(){  //关闭自定义图片弹出框
      this.getMarkList();
      this.dialogModifyMark = false;
    },
    setMarkImg(item){  //设置图标
            /*自定义图标*/
            let imgUrl = require("@/assets/map/mark.png");
            var myIcon = new BMap.Icon(item.img?`${beasconfig.xinextendUrlapi}/icon${item.img}`:imgUrl,
              new BMap.Size(25, 25),
              {
                anchor: new BMap.Size(12, 25) // 图标中央下端的尖角位置。
              }
            );
            let pt = new BMap.Point(item.lng, item.lat);
            var marker = new BMap.Marker(pt, { icon: myIcon}); //创建标注
            if(marker.id) marker.id = item.id;
            marker.addEventListener("click", () => {
              //  console.log(item)
               this.mark = item;
               this.dialogModifyMark = true;
            });
            this.recordMark.push(marker);
            this.map.addOverlay(marker);
    },
    successMarker(item) {
      //成功修改自定义照片
      let imgUrl = "@/assets/map/djj.png";
      let bool = item.online == 3 ? this.InspectionTime(item.pot.Rtime) : false;
      if (item.online != 3) {
        imgUrl = require("@/assets/map/djj.png");
      } else {
        imgUrl = bool
          ? require("@/assets/map/djj2.png")
          : require("@/assets/map/djj1.png");
      }
      this.recordMarker.setIcon(
        new BMap.Icon(
          item.img ? `${beasconfig.xinextendUrlapi}/icon${item.img}` : imgUrl,
          new BMap.Size(25, 25),
          {
            anchor: new BMap.Size(12, 25), // 图标中央下端的尖角位置。
          }
        )
      );
      this.dialogModifyMarker = false;
    },
    cloneMarker() {
      //关闭自定义图片弹出框
      this.dialogModifyMarker = false;
    },
    markerZindex(id) {
      this.markers.forEach((item) => {
        if (item.id == id) {
          item.setTop(true);
          // item.hd.parentNode.parentNode.style.zIndex = 99999999999999999999999; //让标注点再最上面
        } else {
          item.setTop(false);
        }
      });
    },
        /*创建定位点*/
    setMarker(arr) {
      let item = arr[0];
      var pt = new BMap.Point(item.pot.Lng, item.pot.Lat);
      let m = (42 * arr.length + 12);
      let l = arr.length > 5 ? 109:100;
      m = arr.length > 5 ? 235:m;
      // let offset = arr.length > 1 ?new BMap.Size(-68, -m):new BMap.Size(-68, -115);  //没有设置宽度
      let offset = arr.length > 1 ?new BMap.Size(-l, -m):new BMap.Size(-73, -112);
      var opts = {
        position: new BMap.Point(item.pot.Lng, item.pot.Lat), // 指定文本标注所在的地理位置
        offset: offset // 设置文本偏移量
      };
      let isLabel = this.$store.state.recordLable.includes(item.id);  //判断当前label是否有关闭
      let isExceed = item.isExceed;  //判断是否超过五分钟
      if (this.isShow && !isLabel) {
        var html = "";
        var htmlTow = "";
        if(arr.length > 1){
          let bool = arr.every(m => m.online != 3);  //全部离线
          let bool1 = arr.every(m => m.online == 3 && m.isExceed);  //判断是否只有在线5分钟没有上报定位的
          let bool2 = arr.every(m => m.online == 3 && m.isExceed || m.online != 3);
          let lab = '';
          arr.forEach((r,i) =>{
            // let m = JSON.stringify(r);
            lab += `
              <div onclick="clickShowUserInfo(${r.id},${item.id});" class='baidu_label1 ${i == 0?'label_border':''} ${r.online != 3?'baidu_grey':r.isExceed?'baidu_yellow':''}' >
                    <div style="${i == 0?'border: none;':''}" class='baidu_lAbel_box baidu_lAbel_space'>${r.label}</div>
              </div>`;
          })
          html = `<div class="baidu_box"  id='baidu${item.id}' >
                <div class='baidu_lAbel' >
                    <img onclick="closeLabel(${item.id})" class="mouse closeLable" src="${close_label}" alt="">
                    <div>${lab}</div>
                    <div class="big" >
                      <div class='small ${bool?'grey_triangle':bool1 || bool2?'grey_yellow': ''}'></div>
                    </div>
                </div>
               <div id="userMarker${item.id}" class="userMarker_din"></div>
            </div>`;
            // 当多个用户重叠的时候需要用到
        }else{
          htmlTow = `
            <div id="rtime${item.id}" style="display: none;"  class='user_marker ${item.online != 3?'baidu_grey':isExceed?'baidu_yellow':''}' >
              <img onclick="closeLabel(${item.id})" class="mouse closeLable" src="${close_label}" alt="">
              <div class="user_marker_box" >
                <div>名称：${item.label}</div>
                <div class='baidu_lAbel_space' >日期：${item.pot.Rtime}</div>
                <div>定位方式：${item.pot.positionType==2?'基站定位':'北斗/GPS'}</div>
                <div class="user_marker_box" id="expand1${item.id}" style="display: none;" >
                  <div>方向：${global.stringDirection(item.pot.Direction)}</div>
                  <div>海拔：${item.pot.altitude}</div>
                  <div class='baidu_lAbel_space' >速度(KM/H)：${item.pot.Speed}</div>
                  <div style="width:100%;height:1px;background-color: #fff;margin:10px 0px;" ></div>
                  <div style="white-space: break-spaces;font-size: 12px;">温馨提示：数据来自第三方，会存在误差，仅供参考。 </div>
                </div>
                <div style="width:100%;text-align: center;margin-top:10px;" >
                  <img id="expand1${item.id}" style="display: none;" onclick="expand(${item.id},1)" class="mouse margin_auto" src="${sOpen}" alt="">
                  <img id="expand2${item.id}" style="display: block;" onclick="expand(${item.id},2)" class="mouse margin_auto" src="${xOpen}" alt="">
                </div>
              </div>
              <div class="big" style="bottom: -12px;" ><div class='small ${item.online != 3?'grey_triangle':isExceed?'grey_yellow':''}'></div></div>
            </div>
          `;
          html = `<div id='baidu${item.id}' class='baiduinfo_box1'>
            <div id="rtimelabel${item.id}" class='baiduinfo1 ${item.online != 3?'baidu_grey':isExceed?'baidu_yellow':''}'>
              <img onclick="closeLabel(${item.id})" style="right: 10px;" class="mouse closeLable" src="${close_label}" alt="">
              <div class='baiduinfo_label1'>${item.label}</div>
              <div class="big" style="bottom: -12px;" ><div class='small ${item.online != 3?'grey_triangle':isExceed?'grey_yellow':''}'></div></div>
            </div>
            ${htmlTow}
            </div>`;
        }
        // 创建文本标注对象
        var label = new BMap.Label(html, opts);
        label.id = item.id;
        // 自定义文本标注样式
        label.setStyle({
          color: "#343434",
          borderRadius: "5px",
          borderColor: "transparent",
          backgroundColor: "transparent",
          padding: "0px",
          fontSize: "16px",
          fontFamily: "微软雅黑",
          border:'1px solid #e2e2e2'
        });
        label.addEventListener("click", (e) => {
            let id = e.currentTarget.id;
            this.markerZindex(id);
          // console.log("label", e);
        });
        label.addEventListener("mouseover", (e) => {
                let id = e.currentTarget.id;
                this.markerZindex(id);
                var t = document.getElementById(`rtime${id}`);//选取label为百度baidu的id进行隐藏
                var labelId = document.getElementById(`rtimelabel${id}`); 
                if(labelId) labelId.style.display = 'none';// 以块级样式隐藏
                if(t){
                  t.style.display = 'block';// 以块级样式隐藏
                }
        });
        label.addEventListener("mouseout", (e) => {
                let id = e.currentTarget.id;
                var t = document.getElementById(`rtime${id}`);//选取label为百度baidu的id进行隐藏
                var labelId = document.getElementById(`rtimelabel${id}`); 
                if(labelId) labelId.style.display = 'block';// 以块级样式隐藏
                if(t){
                  t.style.display = 'none';// 以块级样式隐藏
                }
          });
        // this.map.addOverlay(label);
      }
      /*自定义图标*/
      let imgUrl = "@/assets/map/djj.png";
      if(item.online != 3){
        imgUrl = require("@/assets/map/djj.png");
      }else{
        imgUrl = isExceed?require("@/assets/map/djj2.png"):require("@/assets/map/djj1.png");
      }
      var myIcon = new BMap.Icon(
        item.img?`${beasconfig.xinextendUrlapi}/icon${item.img}`:imgUrl,
        new BMap.Size(25, 25),
        {
          anchor: new BMap.Size(12, 25) // 图标中央下端的尖角位置。
        }
      );
      var marker = new BMap.Marker(pt, { icon: myIcon}); //创建标注
      marker.id = item.id;
      marker.row = item;
      marker.arr = arr;
      if(this.isShow){
        marker.setLabel(label)
      }
      marker.addEventListener("click", (e) => {
        let id = e.currentTarget.id;
        let row = e.currentTarget.row;
        this.markerUser = row;
        this.dialogModifyMarker = true;
        this.markerZindex(id);
        this.recordMarker = marker;
      });
      this.map.addOverlay(marker);
      this.markers.push(marker);
    },
    /*绘制入口*/
    openDrawing() {
      this.drawingManager.close(); //停止绘制
      this.clearOverwrites();
      this.$refs.mapcustomfence.selectState.isCustom = false;
      this.isCustom = true;
      // this.drawingManager.enableCalculate(); //开启面积计算
    },
    /*画多边形*/
    drawPolylines() {
      if (this.editRegion != 1) {
        this.judgeType();
        this.clearOverwrites();
        this.qcMarker();
      }
      if (this.$refs.mapcustomfence.selectState.isCustom) {
        this.drawingManager.close();
      }
      this.isCustom = false;
      this.eliminateInput();
      this.drawingManager.open(); //开启绘制
      this.mapIconDisplay = false;
      this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYGON);
      this.distinguishType = 1;
      this.distanceTool.close();
      // this.clearOverwrites();
      this.clearTimer();
    },
    /*画圆圈*/
    drawCircle() {
      if (this.editRegion != 1) {
        this.judgeType();
        this.clearOverwrites();
        this.qcMarker();
      }
      if (this.$refs.mapcustomfence.selectState.isCustom) {
        this.drawingManager.close();
      }
      this.isCustom = false;
      this.eliminateInput();
      this.drawingManager.open(); //开启绘制
      this.mapIconDisplay = false;
      this.drawingManager.setDrawingMode(BMAP_DRAWING_CIRCLE);
      this.distinguishType = 2;
      this.distanceTool.close();
      this.clearTimer();
    },
    /*画矩形*/
    drawRectangle() {
      if (this.editRegion != 1) {
        this.judgeType();
        this.clearOverwrites();
        this.qcMarker();
      }
      if (this.$refs.mapcustomfence.selectState.isCustom) {
        this.drawingManager.close();
      }
      this.isCustom = false;
      this.eliminateInput();
      this.drawingManager.open(); //开启绘制
      this.mapIconDisplay = false;
      this.drawingManager.setDrawingMode(BMAP_DRAWING_RECTANGLE);
      this.distinguishType = 3;
      this.distanceTool.close();
      // this.clearOverwrites();
      this.clearTimer();
    },
    /*返回上一步*/
    previousStep() {
      if (this.editRegion == 1 && this.distinguishType == 0) {
        return;
      }
      this.isCustom = true;
      this.theFirstTime = 0;
      this.onmouseout();
      this.mapIconDisplay = false;
      this.createInformationWindow = true;
      var allOverlay = this.map.getOverlays();
      // console.log("allOverlay", allOverlay);
      this.map.ondblclick = null;
      this.drawingManager.close();
      // this.clearOverwrites();
      // this.map.clearOverlays();
      this.clearOverwrites();
      if (this.editRegion != 1) {
        this.editRegion = 1;
        this.initialization();
      }
      return;
      if (allOverlay) {
        for (var i = 0; i < allOverlay.length; i++) {
          //多边行 圆形 正方形
          let obj = allOverlay[i].toString();
          if (obj === "[object Polygon]") {
            let Bo = allOverlay[i].Bo;
            console.log(Bo);
            // console.log(Bo[Bo.length - 1]) this.drawingManager._mask.disableEdgeMove()
            this.drawMapPolygon(Bo[Bo.length - 1]);
          }
        }
        // this.map.removeOverlay(lineArr[lineArr.length - 1])
      }
    },
    eliminateInput() {
      //清空输入框
      if (this.search != "") {
        this.search = "";
      }
    },
    /*删除围栏区域*/
    deleteFenceArea() {
      if (this.editRegion == 1 && this.distinguishType == 0) {
        return;
      }
      this.isCustom = true;
      this.theFirstTime = 0;
      this.onmouseout();
      this.clearOverwrites();
      // this.drawingManager.open();
      this.drawingManager.close();
      this.$refs.mapcustomfence.selectState.isCustom = false;
      this.isCustom = true;
      if (this.editRegion != 1) {
        this.editRegion = 1;
        this.initialization();
      }
      // this.switchOnlineState(this.type);
      return;
      this.map.clearOverlays();
    },
    /*清除覆盖*/
    clearOverwrites() {
      var allOverlay = this.map.getOverlays();
      this.map.ondblclick = null;
      // console.log("allOverlay", allOverlay);

      if (allOverlay) {
        for (var i = 0; i < allOverlay.length; i++) {
          //多边行 圆形 正方形
          let obj = allOverlay[i].toString();
          if (this.editRegion == 1) {
            if (this.distinguishType == 0) {
              if (
                (obj === "[object Polygon]" &&
                  !allOverlay[i].id &&
                  this.distinguishType == 0) ||
                (obj === "[object Circle]" &&
                  !allOverlay[i].id &&
                  this.distinguishType == 0)
              ) {
                this.map.removeOverlay(allOverlay[i]);
              } else if (
                (obj === "[object Polygon]" && allOverlay[i].id) ||
                (obj === "[object Circle]" && allOverlay[i].id)
              ) {
                this.map.removeOverlay(allOverlay[i]);
              }
            } else {
              if (
                (obj === "[object Polygon]" && !allOverlay[i].id) ||
                (obj === "[object Circle]" && !allOverlay[i].id)
              ) {
                this.map.removeOverlay(allOverlay[i]);
              }
            }
          } else {
            if (
              obj === "[object Polygon]" ||
              obj === "[object Circle]" ||
              obj === "[object Vertex]"
            ) {
              this.map.removeOverlay(allOverlay[i]);
            }
          }
        }
      }
    },
    /*提示语*/
    hint(msg) {
      this.$MessageWarning(msg);
    },
    focus() {
      //获取焦点
      this.$store.state.isFocusing = true;
    },
    blur() {
      //失去焦点
      this.$store.state.isFocusing = false;
    },
    qcMarker() {
      //删除指定的Marker 和 Label
      var allOverlay = this.map.getOverlays();
      if (allOverlay.length) {
        for (var i = 0; i < allOverlay.length; i++) {
          if (
            (allOverlay[i].toString() == "[object Marker]" &&
              allOverlay[i].id) ||
            (allOverlay[i].toString() == "[object Label]" && allOverlay[i].id)
          ) {
            this.map.removeOverlay(allOverlay[i]);
          }
        }
      }
    },
    async shapeType(overlay) {
      //区分形状
      let array = this.deweight(this.coordinate);
      // console.log(array)
      let selectData = []; //图形选中的值
      var bool;
      let distinguishType = this.distinguishType;
      var graphical = new GeoUtils();
      this.clearOverwrites();
      //检查类型是否正确
      await array.forEach((item) => {
        if (item["pot"]) {
          var Point = new BMap.Point(item["pot"].Lng, item["pot"].Lat);
          if (distinguishType == 1 || distinguishType == 3) {
            //多边形
            bool = graphical.constructor.isPointInPolygon(Point, overlay);
            if (bool) {
              selectData.push(item);
            }
          } else if (distinguishType == 2) {
            //圆形
            bool = graphical.constructor.isPointInCircle(Point, overlay);
            if (bool) {
              selectData.push(item);
            }
          }
        }
      });
      if (array.length <= 0 || this.type == 2) {
        // this.$MessageWarning('暂无在线成员，无法建立临时群组！');
        this.$MessageWarning("请选择用户单呼或组呼！");
        return;
      }
      if (selectData.length > 0) {
        if (this.type != 2) {
          EventBus.$emit("temporaryGroup", selectData);
        }
      }
    },
  },
  watch: {
    //监听事件
    "$store.state.groupOnline": {
      handler(array) {
        if (array && array.length > 0) {
          let groupData = this.$store.state.groupData;
          if (groupData.length > 0) {
            this.qcMarker();
            this.clearOverwrites();
            let arr = groupData[0].children ? groupData[0].children : groupData;
            let userArray = JSON.parse(JSON.stringify(array[0].onlineArray));
            userArray.forEach((item) => {
              let index = arr.findIndex((one) => one.id == item.id);
              if (index >= 0) {
                if (groupData[0].children) {
                  this.$store.state.groupData[0].children[index] = item;
                } else {
                  this.$store.state.groupData = [item];
                }
              }
            });
            // this.switchOnlineState(this.type);
            this.preventFrequentlyRefresh();
          }
        }
      },
    },
    "$store.state.toUpdateEnclosure": {
      handler(type) {
        if (type == 1 || type == 2) {
          //1是创建围栏  2是编辑
          this.clearOverwrites();
        }
      },
    },
    "$store.state.isMappingTool": {
      handler(bool) {
        if (bool) {
          this.efId = 0;
          this.editRegion = 3;
          this.dialogTitle = "创建围栏";
          this.clearOverwrites();
        }
      },
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ignore{
  width: 100%;
  height: 100%;
  position: relative;
}

.baidu-api {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

/* 去除百度地图版权那行字 和 百度logo */
.baidumap>.BMap_cpyCtrl {
  display: none !important;
}

// .BMap_noprint {
//   display: block !important;
// }

.anchorBL {
  display: none;
}

.search {
  width: 455px;
  position: absolute;
  top: 26px;
  left: 34px;
  z-index: 1100;
  // width: 418px;
  height: 40px;
  overflow: hidden;
  background-color: #327676;
  border: 1px solid #327676;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px;

  &_x {
    width: 50px;
    height: 100%;
    line-height: 37px;
    border-left: 1px solid #e2e2e2;
    color: #ccc;
    font-size: 30px;
    font-weight: 400;
  }

  &_input {
    flex: 1;
    height: 100%;
    background-color: #fff;
    color: #696969 !important;

    /deep/.el-input__inner {
      width: calc(100% - 42px);
      // width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      color: #696969 !important;
      border: none;
      border-radius: 0px;
      padding: 0px;
    }
  }

  &_but {
    width: 58px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #096774;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 17px;
      height: 17px;
    }
  }

}

.search_title {
  // width: 00px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

#mouseOffset {
  color: red;
  font-size: 13px;
  background-color: rgb(255, 255, 255);
  padding: 0px 5px;
  border: 1px solid rgb(0, 0, 0);
}

.flex_align {
  display: flex;
  align-items: center;
}
</style>